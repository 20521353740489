//
// Mixin
//

// Use

@use "sass:math";

// Text truncate

@mixin text-truncate() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin text-truncate-multiple($num) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $num;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

// Safearea

@mixin safearea($top: 0px, $bottom: 0px, $type: 'padding') {
  @if $type == 'padding' {
    padding-top: calc(#{sz($top)} + constant(safe-area-inset-top));
    padding-top: calc(#{sz($top)} + env(safe-area-inset-top));
    padding-bottom: calc(#{sz($bottom)} + constant(safe-area-inset-bottom));
    padding-bottom: calc(#{sz($bottom)} + env(safe-area-inset-bottom));
  } @else {
    margin-top: calc(#{sz($top)} + constant(safe-area-inset-top));
    margin-top: calc(#{sz($top)} + env(safe-area-inset-top));
    margin-bottom: calc(#{sz($bottom)} + constant(safe-area-inset-bottom));
    margin-bottom: calc(#{sz($bottom)} + env(safe-area-inset-bottom));
  }
}

@mixin safearea-top($top: 0px, $type: 'padding') {
  @if $type == 'padding' {
    padding-top: calc(#{sz($top)} + constant(safe-area-inset-top));
    padding-top: calc(#{sz($top)} + env(safe-area-inset-top));
  }

  @if $type == 'margin' {
    margin-top: calc(#{sz($top)} + constant(safe-area-inset-top));
    margin-top: calc(#{sz($top)} + env(safe-area-inset-top));
  }

  @if $type == 'position' {
    top: calc(#{sz($top)} + constant(safe-area-inset-top));
    top: calc(#{sz($top)} + env(safe-area-inset-top));
  }
}

@mixin safearea-bottom($bottom: 0px, $type: 'padding') {
  @if $type == 'padding' {
    padding-bottom: calc(#{sz($bottom)} + constant(safe-area-inset-bottom));
    padding-bottom: calc(#{sz($bottom)} + env(safe-area-inset-bottom));
  }

  @if $type == 'margin' {
    margin-bottom: calc(#{sz($bottom)} + constant(safe-area-inset-bottom));
    margin-bottom: calc(#{sz($bottom)} + env(safe-area-inset-bottom));
  }

  @if $type == 'position' {
    bottom: calc(#{sz($bottom)} + constant(safe-area-inset-bottom));
    bottom: calc(#{sz($bottom)} + env(safe-area-inset-bottom));
  }
}

@mixin safearea-bottom-max($bottom: 0px, $type: 'padding') {
  @if $type == 'padding' {
    padding-bottom: max(#{sz($bottom)}, constant(safe-area-inset-bottom));
    padding-bottom: max(#{sz($bottom)}, env(safe-area-inset-bottom));
  }

  @if $type == 'margin' {
    margin-bottom: max(#{sz($bottom)}, constant(safe-area-inset-bottom));
    margin-bottom: max(#{sz($bottom)}, env(safe-area-inset-bottom));
  }

  @if $type == 'position' {
    bottom: max(#{sz($bottom)}, constant(safe-area-inset-bottom));
    bottom: max(#{sz($bottom)}, env(safe-area-inset-bottom));
  }
}

// Fluid Size

@mixin fluid($type, $min, $max: $min * 1.25, $minBreakpoint: 375, $maxBreakpoint: 750) {
  $maxLessOne: $maxBreakpoint - 1;
  $avg: math.div($max + $min, 2);
 
  #{$type}: #{$min}px;
  
  @media (min-width: #{$minBreakpoint}px) and (max-width: #{$maxLessOne}px) {
    #{$type}: #{$avg}px;
    #{$type}: calc(#{$min}px + (#{$max} - #{$min}) * (100vw - #{$minBreakpoint}px) / (#{$maxBreakpoint} - #{$minBreakpoint}))
  }
  
  @media (min-width: #{$maxBreakpoint}px) {
    #{$type}: #{$max}px;
  }
}
