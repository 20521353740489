//
// Action
//

ngx-smart-modal {
  &[customclass*="action"] {
    .overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: hidden;

      &.nsm-overlay-open {
        background-color: rgba(54, 57, 62, 0.5);
      }
    }

    .nsm-content {
      box-shadow: none;
      background-color: transparent;
      margin: 0;
      padding: 0;

      .nsm-dialog-btn-close {
        display: none;
      }
    }
  }
}

.action {
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  outline: 0;

  @include fluid('padding-left', 45);
  @include fluid('padding-right', 45);
  @include safearea-bottom(35px, 'position');

  &.nsm-dialog-open {
    .action-content {
      transform: translate3d(0, 0, 0);
    }
  }

  &.nsm-dialog-close {
    .action-content {
      transform: translate3d(0, 150%, 0);
    }
  }

  &-content {
    position: relative;
    background-color: #fff;
    box-shadow: 0 sz(1px) sz(20px) rgba(0, 0, 0, 0.1);
    transform: translate3d(0, 150%, 0);
    transition: transform 300ms ease-in-out;
    padding: sz(18px) sz(20px);

    @include fluid('width', 285);
    @include fluid('border-radius', 20);
    @include fluid('padding-top', 18);
    @include fluid('padding-bottom', 18);
    @include fluid('padding-left', 20);
    @include fluid('padding-right', 20);

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      background-color: #d3d3d3;
      z-index: 10;

      @include fluid('top', 10);
      @include fluid('width', 80);
      @include fluid('height', 4);
      @include fluid('border-radius', 4);
    }

    &-item {
      border-top: sz(1px) solid #e6e8f0;

      &:first-child {
        border-top: 0;
      }
    }

    &-btn {
      display: flex;
      align-items: center;
      width: 100%;
      outline: 0;
      border: 0;

      @include fluid('padding-top', 15);
      @include fluid('padding-bottom', 15);
      @include fluid('padding-left', 11);
      @include fluid('padding-right', 11);

      &.danger {
        .action-content-icon {
          fill: $danger;
        }

        .action-content-text {
          color: $danger;
        }
      }
    }

    &-icon {
      @include fluid('width', 16);
      @include fluid('height', 16);
      @include fluid('margin-right', 16);
    }

    &-text {
      font-weight: 400;
      color: #222;

      @include fluid('font-size', 14);
      @include fluid('line-height', 21);
    }
  }
}
