//
// Swiper
//

.story-create-swiper {
  &-pagination {
    display: flex;
    align-items: center;

    .swiper-pagination-bullet {
      flex-shrink: 0;
      border-radius: 50%;

      @include fluid('width', 10);
      @include fluid('height', 10);
      @include fluid('margin-left', 4);
      @include fluid('margin-right', 4);

      &-active {
        @include fluid('width', 20);
        @include fluid('border-radius', 20);
      }
    }

    &-EM01 {
      .swiper-pagination-bullet {
        background-color: #bfdefc;

        &-active {
          background-color: #85c3ff;
        }
      }
    }

    &-EM02 {
      .swiper-pagination-bullet {
        background-color: #d9f99d;

        &-active {
          background-color: #bef264;
        }
      }
    }

    &-EM03 {
      .swiper-pagination-bullet {
        background-color: #c7d2fe;

        &-active {
          background-color: #a5b4fc;
        }
      }
    }

    &-EM04 {
      .swiper-pagination-bullet {
        background-color: #fbcfe8;

        &-active {
          background-color: #f9a8d4;
        }
      }
    }

    &-EM05 {
      .swiper-pagination-bullet {
        background-color: #bbf7d0;

        &-active {
          background-color: #86efac;
        }
      }
    }

    &-EM06 {
      .swiper-pagination-bullet {
        background-color: #f5d0fe;

        &-active {
          background-color: #f0abfc;
        }
      }
    }

    &-EM07 {
      .swiper-pagination-bullet {
        background-color: #cbd5e1;

        &-active {
          background-color: #94a3b8;
        }
      }
    }

    &-EM08 {
      .swiper-pagination-bullet {
        background-color: #fed7aa;

        &-active {
          background-color: #fb923c;
        }
      }
    }

    &-EM09 {
      .swiper-pagination-bullet {
        background-color: #fda4af;

        &-active {
          background-color: #fb7185;
        }
      }
    }

    &-EM10 {
      .swiper-pagination-bullet {
        background-color: #bae6fd;

        &-active {
          background-color: #7dd3fc;
        }
      }
    }

    &-EM11 {
      .swiper-pagination-bullet {
        background-color: #fef08a;

        &-active {
          background-color: #fde047;
        }
      }
    }

    &-EM12 {
      .swiper-pagination-bullet {
        background-color: #fca5a5;

        &-active {
          background-color: #f87171;
        }
      }
    }
  }
}
