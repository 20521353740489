//
// Ionic
//

// Import Ionic

@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';

// Custom

:root {
  --ion-font-family: 'Noto Sans KR', sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  ion-content {
    --background: #fff;
    --color: #222;
  }

  ion-input {
    --color: #222;
    --placeholder-color: #999;
    --background: transparent;
    --padding-bottom: 0;
    --padding-end: 0;
    --padding-start: 0;
    --padding-top: 0;
  }

  ion-textarea {
    --padding-bottom: 0;
    --padding-end: 0;
    --padding-start: 0;
    --padding-top: 0;
    margin-top: 0;
  }

  ion-datetime {
    --background: transparent;
    --background-rgb: transparent;
  }
}

.native-input {
  line-height: inherit;
}
