//
// Form
//

.form {
  &-control {
    display: flex;
    flex-direction: column;

    + .form-control {
      @include fluid('margin-top', 24);
    }

    &-label {
      display: block;
      font-weight: 400;
      color: #85c3ff;

      @include fluid('font-size', 14);
      @include fluid('line-height', 21);
      @include fluid('margin-bottom', 4);
    }

    &-input {
      display: block;
      width: 100%;
      font-weight: 400;
      color: $text;
      border: sz(1px) solid #85c3ff;
      background-color: #dcecfb;
      outline: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      @include fluid('font-size', 14);
      @include fluid('line-height', 21);
      @include fluid('border-radius', 12);
      @include fluid('padding-top', 18);
      @include fluid('padding-bottom', 18);
      @include fluid('padding-left', 12);
      @include fluid('padding-right', 12);

      &::placeholder {
        color: $text;
      }
    }
  }

  &-btn {
    @include fluid('margin-top', 40);
  }
}
