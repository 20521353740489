//
// Common
//

*,
*::before,
*::after {
  box-sizing: border-box;
}

nav,
main,
aside,
section,
article {
  display: block;
}

html,
body {
  height: 100%;
  font-size: 100%;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  margin: 0;
  font-family: 'Noto Sans KR', sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  background-color: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

ul,
ol,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

input,
select,
textarea {
  &:focus {
    outline: 0;
  }
}

a,
button {
  &:focus {
    outline: 0;
  }

  &:hover {
    cursor: pointer;
  }
}

table {
  border-collapse: collapse;
}

.hidden {
  display: none;
}

.btn,
button {
  outline: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
}

.plt-desktop,
.plt-mobileweb {
  body {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 750px;
  }
}
