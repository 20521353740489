//
// Variable
//

// Use

@use "sass:math";

// Color

$white: #fff !default;
$black: #000 !default;

$primary: #f7fbfe !default;
$success: #4ade80 !default;
$info: #38bdf8 !default;
$warning: #fde047 !default;
$danger: #ff6760 !default;
$text: #36393e !default;

// Color contrast

$yiq-text-dark: $black !default;
$yiq-text-light: $white !default;
$yiq-contrasted-threshold: 190 !default;

// Z-Index

$zindex-1000: 1000 !default;
$zindex-1010: 1010 !default;
$zindex-1020: 1020 !default;
$zindex-1030: 1030 !default;
$zindex-1040: 1040 !default;
$zindex-1050: 1050 !default;
$zindex-1060: 1060 !default;
$zindex-1070: 1070 !default;
$zindex-1080: 1080 !default;
$zindex-1090: 1090 !default;

// Ratio

$ratio: math.div(210, 148) !default;
$ratioPercent: floor(math.div(210, 148) * 100%) !default;

// Avatar

$avatar-line-height: 1.25 !default;
$avatar-zindex: 1 !default;

// Divider

$divider-font-size: sz(14px) !default;
$divider-border-width: sz(1px) !default;
$divider-border-color: #ddd !default;

// Loading

$loading-backdrop-bg: $white !default;
$loading-backdrop-opacity: .5 !default;

$loading-width: sz(25px) !default;
$loading-height: $loading-width !default;
$loading-border-width: sz(1px) !default;

$loading-width-lg: sz(40px) !default;
$loading-height-lg: $loading-width-lg !default;
$loading-border-width-lg: sz(2px) !default;

$loading-width-sm: sz(15px) !default;
$loading-height-sm: $loading-width-sm !default;
$loading-border-width-sm: sz(1px) !default;
