//
// Btn
//

.btn {
  &-small {
    font-weight: 400;

    @include fluid('height', 28);
    @include fluid('font-size', 12);
    @include fluid('line-height', 18);
    @include fluid('border-radius', 16);
    @include fluid('padding-top', 5);
    @include fluid('padding-bottom', 5);
    @include fluid('padding-left', 8);
    @include fluid('padding-right', 8);

    .btn-icon {
      @include fluid('width', 12);
      @include fluid('height', 12);
      @include fluid('margin-left', 10);
    }
  }

  &-medium {
    font-weight: 400;

    @include fluid('height', 41);
    @include fluid('font-size', 14);
    @include fluid('line-height', 21);
    @include fluid('border-radius', 20);
    @include fluid('padding-top', 10);
    @include fluid('padding-bottom', 10);
    @include fluid('padding-left', 12);
    @include fluid('padding-right', 12);

    .btn-icon {
      @include fluid('width', 16);
      @include fluid('height', 16);
      @include fluid('margin-left', 10);
    }
  }

  &-large {
    font-weight: 400;

    @include fluid('height', 56);
    @include fluid('font-size', 16);
    @include fluid('line-height', 24);
    @include fluid('border-radius', 12);
    @include fluid('padding-top', 16);
    @include fluid('padding-bottom', 16);
    @include fluid('padding-left', 22);
    @include fluid('padding-right', 22);

    .btn-icon {
      @include fluid('width', 24);
      @include fluid('height', 24);
      @include fluid('margin-left', 10);
    }
  }

  &-primary {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #fff;
    background-color: #36393e;
    box-shadow: 0 sz(2px) sz(6px) rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
  }

  &-secondary {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #85c3ff;
    border: sz(2px) solid #85c3ff;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.25));
    box-sizing: border-box;
  }

  &-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-weight: 400;
    color: $text;

    @include fluid('font-size', 14);
    @include fluid('line-height', 21);
    @include fluid('padding-top', 16);
    @include fluid('padding-bottom', 16);
  }

  &.loading {
    font-size: 0;

    &::before {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
    }
  }
}
