//
// Modal
//

ngx-smart-modal {
  &[customclass*="modal"] {
    .overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: hidden;

      &.nsm-overlay-open {
        background-color: rgba(54, 57, 62, 0.5);
      }
    }

    .nsm-dialog-btn-close {
      display: none;
    }
  }
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  outline: 0;

  &.nsm-dialog-open {
    .modal-content {
      opacity: 1;
    }
  }

  &.nsm-dialog-close {
    .modal-content {
      opacity: 0;
    }
  }

  &-content {
    position: relative;
    background-color: #fff;
    box-shadow: 0 sz(1px) sz(1px) rgba(100, 116, 139, 0.06), 0 sz(1px) sz(2px) rgba(100, 116, 139, 0.1);
    opacity: 0;
    transition: opacity 300ms ease-in-out;

    @include fluid('width', 285);
    @include fluid('border-radius', 8);
  }

  &-header {
    display: flex;
    align-items: center;

    @include fluid('border-top-left-radius', 8);
    @include fluid('border-top-right-radius', 8);
    @include fluid('padding', 16);

    &-title {
      flex: 1;
      font-weight: 700;
      color: #25282b;

      @include fluid('font-size', 14);
      @include fluid('line-height', 21);
    }

    &-description {
      width: 100%;
      text-align: center;
      color: $text;

      @include fluid('font-size', 12);
      @include fluid('line-height', 18);
    }

    &-close {
      position: relative;
      display: block;
      font-size: 0;
      border-radius: 50%;
      background-color: #f6f8fb;

      @include fluid('width', 28);
      @include fluid('height', 28);

      &-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        fill: $text;

        @include fluid('width', 20);
        @include fluid('height', 20);
      }
    }
  }

  &-main {
    @include fluid('padding-top', 16);
    @include fluid('padding-bottom', 16);

    &-icon {
      text-align: center;

      @include fluid('padding-top', 20);

      + .modal-main-text {
        @include fluid('padding-top', 12);
      }

      &-success {
        @include fluid('width', 36);
        @include fluid('height', 36);
      }
    }

    &-text {
      font-weight: 400;
      text-align: center;

      @include fluid('font-size', 12);
      @include fluid('line-height', 18);
    }
  }

  &-footer {
    @include fluid('border-bottom-left-radius', 8);
    @include fluid('border-bottom-right-radius', 8);
    @include fluid('padding', 16);

    &-btn {
      box-shadow: none;
    }
  }
}
