//
// Editor
//

.keyboard {
  .editor-toolbar {
    visibility: hidden;
    transform: translateY(200%);
    transition: transform 320ms ease-in-out;
  }

  &.keyboard-open,
  &.popup-open {
    .editor-toolbar {
      visibility: visible;
      transform: translateY(0);
    }
  }

  &:not(.keyboard-open):not(.popup-open) {
    .editor {
      height: 100% !important;

      &-container,
      &-wrap,
      &-content {
        height: 100% !important;
      }
    }
  }
}

.story-create-editor,
.story-modify-editor {
  .editor {
    &-content {
      @include fluid('padding-top', 40);
      @include fluid('padding-bottom', 40);
      @include fluid('padding-left', 40);
      @include fluid('padding-right', 40);
    }

    &-placeholder {
      @include fluid('top', 40);
      @include fluid('left', 40);
    }
  }
}

.letter-create-editor,
.contact-create-editor {
  .editor {
    &-content {
      @include fluid('padding-left', 40);
      @include fluid('padding-right', 40);
      @include fluid('padding-bottom', 20);
    }

    &-placeholder {
      @include fluid('left', 40);
    }
  }
}

.editor {
  &-wrap {
    position: relative;
    height: 100%;
  }

  &-content,
  &-viewer {
    height: 100%;
    outline: 0;
    color: $text;
    line-height: 1.7;
    user-select: text;

    @include fluid('font-size', 14);

    &:empty {
      + .editor-placeholder {
        display: block;
      }
    }

    &:focus {
      + .editor-placeholder {
        display: none;
      }
    }

    h1 {
      margin: 0;
      padding: 0;

      @include fluid('font-size', 30);
    }

    h2 {
      margin: 0;
      padding: 0;

      @include fluid('font-size', 24);
    }

    h3 {
      margin: 0;
      padding: 0;

      @include fluid('font-size', 18);
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0;
    }
  }

  &-placeholder {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    font-weight: 400;
    line-height: 1.7;
    color: #999;
    pointer-events: none;

    @include fluid('font-size', 14);
  }
}
