//
// Toast
//

// Import scss

@import 'ngx-toastr/toastr';

// Basic

.toast-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include safearea();

  .ngx-toastr {
    width: auto !important;
    width: 90%;
    margin: 0 auto 0;

    @include fluid('margin-top', 20);
    @include fluid('max-width', 340);
  }

  .toast-success,
  .toast-error,
  .toast-info,
  .toast-warning {
    background-image: none;
    box-shadow: 0 sz(1px) sz(20px) rgba(0, 0, 0, 0.2);

    @include fluid('padding-top', 12);
    @include fluid('padding-bottom', 12);
    @include fluid('padding-left', 20);
    @include fluid('padding-right', 20);
    @include fluid('border-radius', 30);
  }

  .toast-message {
    font-weight: 500;

    @include fluid('font-size', 14);
    @include fluid('line-height', 24);
  }
  
  .toast-success {
    background-color: $success;
  }

  .toast-error {
    background-color: $danger;
  }

  .toast-info {
    background-color: $info;
  }

  .toast-warning {
    background-color: $warning;
  }
}